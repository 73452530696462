import React from 'react'
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import BackgroundImg from 'gatsby-background-image'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Layout from '../../../../../../components/layout'
import SEO from '../../../../../../components/seo'
import Heading from '../../../../../../components/heading'
import HeroBox from '../../../../../../components/herobox'
import { ButtonBase } from '@material-ui/core'
import orange from '@material-ui/core/colors/orange'
import { Alert, AlertTitle } from '@material-ui/lab'

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    root: {

    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        minHeight: '60vh',
        padding: '15px',
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    wrapper: {
        minHeight: '60vh',
        padding: '25px',
        // backgroundColor: 'rgba(0,0,0,.3)',
        color: '#ffffff',
        borderColor: 'rgba(255,255,255,.5)',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    // hero: {
    //     padding: '1rem',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     flexDirection: 'column',
    //     minHeight: '80vh',
    //     textAlign: 'center',
    //     color: '#ffffff',
    // },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    heading2s: {
        // color: '#48bd98',
        // marginTop: '5rem',
        '&::before': {
            content: '""',
            display: 'inline-block',
            position: 'relative',
            top: '-.1em',
            verticalAlign: 'middle',
            height: '1em',
            borderLeftColor: '#48bd98',
            borderLeftWidth: '7px',
            borderLeftStyle: 'solid',
            marginRight: '10px',
        }
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    // Card
    cardRoot: {
        display: 'flex',

    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flex: '1 0 auto',
    },
    //Grid
    gridRoot: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
    },
    gridGroup2: {
        padding: '0 30px',
    },
    gridPaper: {
        padding: theme.spacing(2),
        // margin: 'auto',
        maxWidth: 600,
    },
    gridPaper2: {
        padding: theme.spacing(2),
        // margin: 'auto',
        maxWidth: 600,
    },
    gridImage: {
        width: 250,
        // height: 150,
    },
    gridImage2: {
        width: 350,
        // height: 150,
    },
    gridImg: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    gridImg2: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    //Stepper
    stepperRoot: {
        width: '100%',
    },
    stepperButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        }
    },
    stepperButtonTxt: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
        marginBottom: '2rem',
    },
    stLabel: {
        padding: '1px',
        backgroundColor: orange[600],
        color: '#ffffff',
        borderRadius: '2px',
    },
    //Mobile Stepper
    msRoot: {
        maxWidth: 500,
        flexGrow: 1,
    },
    msHeader: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        // paddingLeft: theme.spacing(4),
        // backgroundColor: theme.palette.background.default,
    },
    msBody: {
        // display: 'flex',
        // alignItems: 'center',
        padding: '15px'
    },
    msImg: {
        // height: 255,
        maxWidth: 1000,
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        marginBottom: '0',
    },
    msGridItem: {
        marginTop: '5rem',
    },
    msButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
    // Alert
    alertContainer: {
        // width: '100%',
        marginTop: '2rem',
        '& > * + *': {
            marginTop: theme.spacing(2),
        }
    },
    // Img
    imageContainer: {
        margin: '15px',
        '& > *': {
            borderRadius: '3px',
        }
    },
    // Full Screen Dialog
    fsdAppBar: {
      position: 'relative',
    },
    fsdTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    fsdGoogleMaps: {
      height: '100%',
    },
    fsdIframe: {
      width: '100%',
      height: '100%',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IsawaonsenStationWineryPage = ({ data }) => {
    const classes = useStyles();
    const language = 'zh_hant';
    const slug = 'station/isawaonsen/wine/winery';
    const notChild = false;
    const child = true;
    const pageTitle = '葡萄酒之旅！';
    const descriptions = [
        '',
        ''
    ];

    /** MobileStepper 1 */
    const slides1 = [
        {
            label: '',
            imgPath: data.wine1.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.wine2.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.wine3.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.wine4.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.wine5.childImageSharp.fluid.src,
        }
    ];
    const maxSlides1 = slides1.length;
    const [activeSlides1, setActiveSlides1] = React.useState(0);
    const handleNextSlides1 = () => {
        setActiveSlides1(prevActiveSlides1 => prevActiveSlides1 + 1);
    }
    const handleBackSlides1 = () => {
        setActiveSlides1(prevActiveSlides1 => prevActiveSlides1 -1);
    }
    /** MobileStepper 2 */
    const slides2 = [
        {
            label: '',
            imgPath: data.wineset.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.wine7.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.wine8.childImageSharp.fluid.src,
        }
    ];
    const maxSlides2 = slides2.length;
    const [activeSlides2, setActiveSlides2] = React.useState(0);
    const handleNextSlides2 = () => {
        setActiveSlides2(prevActiveSlides2 => prevActiveSlides2 + 1);
    }
    const handleBackSlides2 = () => {
        setActiveSlides2(prevActiveSlides2 => prevActiveSlides2 -1);
    }
    /** MobileStepper 3 */
    const slides3 = [
        {
            label: '',
            imgPath: data.fujimiya.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.omiyage.childImageSharp.fluid.src,
        }
    ];
    const maxSlides3 = slides3.length;
    const [activeSlides3, setActiveSlides3] = React.useState(0);
    const handleNextSlides3 = () => {
        setActiveSlides3(prevActiveSlides3 => prevActiveSlides3 + 1);
    }
    const handleBackSlides3 = () => {
        setActiveSlides3(prevActiveSlides3 => prevActiveSlides3 -1);
    }
    /** MobileStepper 4 */
    const slides4 = [
        {
            label: '',
            imgPath: data.wine10.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.wine11.childImageSharp.fluid.src,
        }
    ];
    const maxSlides4 = slides4.length;
    const [activeSlides4, setActiveSlides4] = React.useState(0);
    const handleNextSlides4 = () => {
        setActiveSlides4(prevActiveSlides4 => prevActiveSlides4 + 1);
    }
    const handleBackSlides4 = () => {
        setActiveSlides4(prevActiveSlides4 => prevActiveSlides4 -1);
    }
    /** MobileStepper 5 */
    const slides5 = [
        {
            label: '',
            imgPath: data.mars0.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.mars1.childImageSharp.fluid.src,
        }
    ];
    const maxSlides5 = slides5.length;
    const [activeSlides5, setActiveSlides5] = React.useState(0);
    const handleNextSlides5 = () => {
        setActiveSlides5(prevActiveSlides5 => prevActiveSlides5 + 1);
    }
    const handleBackSlides5 = () => {
        setActiveSlides5(prevActiveSlides5 => prevActiveSlides5 -1);
    }
    /** MobileStepper 6 */
    const slides6 = [
        {
            label: '',
            imgPath: data.mars2.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.mars3.childImageSharp.fluid.src,
        }
    ];
    const maxSlides6 = slides6.length;
    const [activeSlides6, setActiveSlides6] = React.useState(0);
    const handleNextSlides6 = () => {
        setActiveSlides6(prevActiveSlides6 => prevActiveSlides6 + 1);
    }
    const handleBackSlides6 = () => {
        setActiveSlides6(prevActiveSlides6 => prevActiveSlides6 -1);
    }
    /** MobileStepper 7 */
    const slides7 = [
        {
            label: '',
            imgPath: data.mars4.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.mars5.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.mars6.childImageSharp.fluid.src,
        }
    ];
    const maxSlides7 = slides7.length;
    const [activeSlides7, setActiveSlides7] = React.useState(0);
    const handleNextSlides7 = () => {
        setActiveSlides7(prevActiveSlides7 => prevActiveSlides7 + 1);
    }
    const handleBackSlides7 = () => {
        setActiveSlides7(prevActiveSlides7 => prevActiveSlides7 -1);
    }
    /** MobileStepper 8 */
    const slides8 = [
        {
            label: '',
            imgPath: data.monde0.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.monde1.childImageSharp.fluid.src,
        }
    ];
    const maxSlides8 = slides8.length;
    const [activeSlides8, setActiveSlides8] = React.useState(0);
    const handleNextSlides8 = () => {
        setActiveSlides8(prevActiveSlides8 => prevActiveSlides8 + 1);
    }
    const handleBackSlides8 = () => {
        setActiveSlides8(prevActiveSlides8 => prevActiveSlides8 -1);
    }
    /** MobileStepper 9 */
    const slides9 = [
        {
            label: '',
            imgPath: data.monde3.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.monde4.childImageSharp.fluid.src,
        }
    ];
    const maxSlides9 = slides9.length;
    const [activeSlides9, setActiveSlides9] = React.useState(0);
    const handleNextSlides9 = () => {
        setActiveSlides9(prevActiveSlides9 => prevActiveSlides9 + 1);
    }
    const handleBackSlides9 = () => {
        setActiveSlides9(prevActiveSlides9 => prevActiveSlides9 -1);
    }

    /** Full Screen Dialog 1 */
    const[open1, setOpen1] = React.useState(false);
    const handleClickOpen1 = () => {
      setOpen1(true);
    };
    const handleClose1 = () => {
      setOpen1(false);
    };

    /** Full Screen Dialog 2 */
    const[open2, setOpen2] = React.useState(false);
    const handleClickOpen2 = () => {
      setOpen2(true);
    };
    const handleClose2 = () => {
      setOpen2(false);
    };

    return (
        <Layout language={language} slug={slug}>
            <SEO title={pageTitle} />
            <BackgroundImg
                Tag="section"
                fluid={data.hero.childImageSharp.fluid}
                backgourndColor={`#48bd98`}
            >
                <section className={classes.hero}><div className={classes.wrapper}>
                    {/* <Typography className={classes.headerH3} variant="h3" content="h3"></Typography> */}
                    <Typography className={classes.HeaderH2} variant="h2" content="h2">{pageTitle}</Typography>
                    <div className={classes.paragraphs}>
                        {
                            descriptions.map((description, i) => (
                                <p className={classes.paragraph} key={i}><span className={classes.span}>{description}</span></p>
                            ))
                        }
                    </div>
                </div></section>
            </BackgroundImg>
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">
                    <section>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} className={classes.msGridItem}>
                          <div clasName={classes.msRoot}>
                            <Paper square elevation={0} className={classes.msHeader}>
                                <Typography><h2><span className={classes.heading2s}>車站二樓的便利商店NewDays</span></h2></Typography>
                            </Paper>
                            <div className={classes.msBody}>
                                <p>「步行1分鐘」買些葡萄酒上路吧！!</p>
                            </div>
                            <img 
                                className={classes.msImg}
                                src={data.newdays.childImageSharp.fluid.src}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>除了多種在地葡萄酒之外，</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>還有名揚法國的本地葡萄酒。</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides1[activeSlides1].imgPath}
                                        alt={slides1[activeSlides1].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides1}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides1}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides1} disabled={activeSlides1 === maxSlides1 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides1} disabled={activeSlides1 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>Rail Bar</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>1400日圓的迷你葡萄酒加鹿肉乾！連酒杯都附上了。</p>
                                        <Alert severity="error">日本的新幹線、特急電車或地方上的列車可飲酒，但請適量飲用以免影響他人。 </Alert>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides2[activeSlides2].imgPath}
                                        alt={slides2[activeSlides2].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides2}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides2}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides2} disabled={activeSlides2 === maxSlides2 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides2} disabled={activeSlides2 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>車站前還有名產店</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>「步行1分鐘」買些葡萄酒上路吧！!</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides3[activeSlides3].imgPath}
                                        alt={slides3[activeSlides3].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides3}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides3}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides3} disabled={activeSlides3 === maxSlides3 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides3} disabled={activeSlides3 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem} id="wineryInfo">
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>參觀 </span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>無需預約，隨時都可以入內參觀。</p>
                                        <p>有葡萄酒和葡萄汁可以免費試飲，全家大小都可以逛得開開心心。</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides4[activeSlides4].imgPath}
                                        alt={slides4[activeSlides4].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides4}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides4}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides4} disabled={activeSlides4 === maxSlides4 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides4} disabled={activeSlides4 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem} id="moreFootSpa">
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>MARS山梨葡萄酒廠</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>「步行10分鐘」去葡萄酒廠逛逛吧！ ! </p>
                                        <p>離車站最近的葡萄酒廠，每年大約有九萬名遊客到此一遊。</p>
                                        <p>免費試飲的葡萄酒多達7、8種，也有葡萄果汁可以自由飲用。如果事先預約，還可以在貴賓室細細品嚐得過大獎的知名葡萄酒（收費試飲）。</p>
                                        <Button
                                            variant="contained"
                                            // endIcon={<KeyboardArrowRight />}
                                            className={classes.msButton}
                                            onClick={handleClickOpen1}
                                        >地圖</Button>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides5[activeSlides5].imgPath}
                                        alt={slides5[activeSlides5].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides5}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides5}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides5} disabled={activeSlides5 === maxSlides5 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides5} disabled={activeSlides5 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem} id="lodgingInThisTown">
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>自由試飲</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>有多種葡萄酒可以自由試飲，從酒桶直接注入杯中。</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides6[activeSlides6].imgPath}
                                        alt={slides6[activeSlides6].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides6}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides6}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides6} disabled={activeSlides6 === maxSlides6 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides6} disabled={activeSlides6 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem} id="lodgingInThisTown">
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>豐富多樣的在地品牌。</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides7[activeSlides7].imgPath}
                                        alt={slides7[activeSlides7].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides7}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides7}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides7} disabled={activeSlides7 === maxSlides7 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides7} disabled={activeSlides7 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                            <div clasName={classes.msRoot}>
                              <Paper square elevation={0} className={classes.msHeader}>
                                  <Typography><h2><span className={classes.heading2s}>付費試飲有更高級的珍藏名品。</span></h2></Typography>
                              </Paper>
                              <div className={classes.msBody}>
                              </div>
                              <img 
                                  className={classes.msImg}
                                  src={data.mars7.childImageSharp.fluid.src}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} className={classes.msGridItem} id="lodgingInThisTown">
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>MONDE酒造</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                      <p>「步行10分鐘」去葡萄酒廠逛逛吧！! </p>
                                      <p>從得過大獎的高級葡萄酒到日常生活中的佐餐葡萄酒，口味應有盡有，種類豐富齊全。</p>
                                      <p>寬廣的大廳有葡萄酒、甜酒和果汁等可以免費試飲。</p>
                                      <Button
                                            variant="contained"
                                            // endIcon={<KeyboardArrowRight />}
                                            className={classes.msButton}
                                            onClick={handleClickOpen2}
                                        >地圖</Button>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides8[activeSlides8].imgPath}
                                        alt={slides8[activeSlides8].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides8}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides8}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides8} disabled={activeSlides8 === maxSlides8 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides8} disabled={activeSlides8 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                            <div clasName={classes.msRoot}>
                              <Paper square elevation={0} className={classes.msHeader}>
                                  <Typography><h2><span className={classes.heading2s}>可免費試飲的葡萄酒多達15種以上。</span></h2></Typography>
                              </Paper>
                              <div className={classes.msBody}>
                              </div>
                              <img 
                                  className={classes.msImg}
                                  src={data.monde2.childImageSharp.fluid.src}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} className={classes.msGridItem} id="lodgingInThisTown">
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>除了葡萄酒之外還有許多趣味盎然的名產</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                      <Alert severity="error">在新幹線和對號入座的特急列車當中可以飲酒，但請淺嘗則止以免影響到其他乘客。</Alert>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides9[activeSlides9].imgPath}
                                        alt={slides9[activeSlides9].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides9}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides9}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides9} disabled={activeSlides9 === maxSlides9 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides9} disabled={activeSlides9 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                      </Grid>
                    </section>
                </Box>
            </Container>
            <Dialog
              fullScreen
              open={open1}
              onClose={handleClose1}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.fsdAppBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose1}
                    arial-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.fsdTitle}>
                    MARS山梨葡萄酒廠
                  </Typography>
                  <Button
                    autoFocus
                    color="inherit"
                    onClick={handleClose1}
                  >
                    Close
                  </Button>
                </Toolbar>
              </AppBar>
              <section className={classes.fsdGoogleMaps}>
                <iframe
                  className={classes.fsdIframe}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.717900581557!2d138.6411853156116!3d35.65932133878012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601bfc069a4499d3%3A0xcea547d5a29d5f83!2z44Oe44Or44K5IOWxseaiqOODr-OCpOODiuODquODvCDmnKzlnYrphZLpgKA!5e0!3m2!1sja!2sus!4v1559910898603!5m2!1sja!2sus"
                  allowfullscreen
                ></iframe>
              </section>
            </Dialog>
            <Dialog
              fullScreen
              open={open2}
              onClose={handleClose2}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.fsdAppBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose2}
                    arial-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.fsdTitle}>
                    MONDE酒造
                  </Typography>
                  <Button
                    autoFocus
                    color="inherit"
                    onClick={handleClose2}
                  >
                    Close
                  </Button>
                </Toolbar>
              </AppBar>
              <section className={classes.fsdGoogleMaps}>
                <iframe
                  className={classes.fsdIframe}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.984304317564!2d138.63546331561164!3d35.65275833913961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601bfbf7c006b87b%3A0x1e223ec701016c31!2z44Oi44Oz44OH6YWS6YCg!5e0!3m2!1sja!2sus!4v1559911555498!5m2!1sja!2sus"  
                  allowfullscreen
                ></iframe>
              </section>
            </Dialog>
        </Layout>
    );
    // return <pre>{JSON.stringify(data, null, 4)}</pre>
}

export const query = graphql`
  {
    hero:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fujimiya:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/fujimiya.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newdays:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/newdays.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    omiyage:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/omiyage.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wineset:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wineset.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine3:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine4:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-4.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine5:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-5.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine6:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-6.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine7:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-7.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine8:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-8.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine9:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-9.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine10:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-10.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wine11:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/wine/wine-11.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    monde0:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/monde/monde-winery-0.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    monde1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/monde/monde-winery-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    monde2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/monde/monde-winery-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    monde3:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/monde/monde-winery-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    monde4:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/monde/monde-winery-4.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mars0:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/mars/mars-winery-0.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mars1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/mars/mars-winery-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mars2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/mars/mars-winery-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mars3:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/mars/mars-winery-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mars4:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/mars/mars-winery-4.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mars5:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/mars/mars-winery-5.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mars6:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/mars/mars-winery-6.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mars7:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/winery/mars/mars-winery-7.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IsawaonsenStationWineryPage